import { RectangleGeometry,PointWithMediaGeometry } from "../geometry.js";
let geometry_js = require("../geometry.js");
import { DrawScope } from "../DrawScope.js";
import { SizeType } from "../resources/ImageResource.js";

export class InteractiveArea {
  static typename = "interactive.area";
  interactive;
  geometry;
  json;

  visual_resource;
  canvasElement;
  on_visual_loaded;
  get resources() {
    return this.interactive.resources;
  }
  get icanvas() {
    return this.interactive.icanvas;
  }
  constructor(interactive, json) {
    this.interactive = interactive;
    this.json = json;
    var asRectangle = json["geometry.rectangle"];
    if (asRectangle) {
      this.geometry = new RectangleGeometry(asRectangle);
    }

    var asPoint= json["geometry.point"];
    if (asPoint) {
      this.geometry = new PointWithMediaGeometry(asPoint,()=>{return this.visual_resource;});
    }


  }

  set scene(value) {
    this.geometry.isRelativeTo = value;
  }

  drawFrame(icanvas) {
    this.geometry.draw(icanvas, DrawScope.Debug);
  }

  mousedown(ievent) {}

  isIEventOnInteractiveArea(ievent){
    let mouse_point = geometry_js.mouseEventToPoint(ievent.e);
    return this.geometry.containsPoint(mouse_point);
  }
  getScreenSpaceAreaRect(){
    return this.geometry.get_absolute_rect_shape();
  }

  mouseup(ievent) {
    if (this.isIEventOnInteractiveArea(ievent)) {
      this.interactive.onMouseUp(ievent, this);
    }
  }
  onTouchTap(ievent) {
    if (this.isIEventOnInteractiveArea(ievent)) {
      this.interactive.onMouseUp(ievent, this);
    }
  }

  mousemove(ievent) {}

  start() {
    this.set_visual(()=>{this.on_visual_loaded?.();});
  }

  stop() {
    this.removeCanvasElement();
  }

  getResourcePath() {
    return this.json.resourcePath || this.interactive.getResourcePath();
  }

  set_visual(onVisualLoaded=undefined) {
    this.removeCanvasElement();
    let self = this;
    this.visual_resource = this.resources.requestResourceFromJson(this.json, this.getResourcePath(),this.json.resourcePath2,onVisualLoaded);

    if (this.visual_resource) {
      if (this.geometry instanceof RectangleGeometry) {
        this.visual_resource.sizeType = SizeType.ScaleToFitRectangle;
        this.visual_resource.getRectangle = () => {
          return self.geometry.get_absolute_shape();
        };
      }else if (this.geometry instanceof PointWithMediaGeometry) {
        this.visual_resource.sizeType = SizeType.ScaleToFitRectangle;
        this.visual_resource.getRectangle = () => {
          return self.geometry.get_absolute_rect_shape();
        };
      }

      this.canvasElement = this.visual_resource.getCanvasElement();
      this.canvasElement.draw_order=this.interactive.scene.sceneLayerOrder;
      //this.canvasElement.invalidate();

      
     // this.canvasElement.waitOn(this.interactive.scene.canvasElement);

      this.icanvas.addElement(this.canvasElement);
    }
    else{
      onVisualLoaded?.();
    }

  }

  removeCanvasElement() {
    if (this.canvasElement) {
      this.icanvas.removeElement(this.canvasElement);
      this.canvasElement = undefined;
    }
  }
  collectCanvasElements(result) {
    if (this.canvasElement) {
      result.push(this.canvasElement);
    }
  }
  setAreaReletivePosition(point) {
    if (this.geometry instanceof RectangleGeometry) {
      this.geometry.shape.p = point;
      this.icanvas.invaidate();
    //  console.log("request invaidate canvas");
    } else if (this.geometry instanceof PointWithMediaGeometry) {
      this.geometry.shape = point;
      this.icanvas.invaidate();
    //  console.log("request invaidate canvas");
    }
  }
}
