import { InteractiveArea } from "./InteractiveArea.js";
import { InteractiveInput } from "./InteractiveInput.js";
import { Action } from "./actions/action.js";
import { Actions, newActionFromJson } from "./actions/actions.js";
import { RectangleGeometry } from "../geometry.js";
import { InteractiveLayers } from "./InteractiveLayers.js";
import { UserInterfaceElements } from "../view/userInterfaceElements.js";

export class Interactive {
  static typename = "interactive";
  scene_cache;
  area;
  inputs = [];
  name;
  json;
  actions = [];
  ui;

  get isTemporary() {
    return this.json.isTemporary;
  }
  set isTemporary(v) {
    this.json.isTemporary = v;
  }

  get name() {
    return this.json.name;
  }
  set name(v) {
    this.json.name = v;
  }
  get account() {
    return this.scene.sceneGraph.account;
  }

  get resources() {
    return this.scene.resources;
  }
  get icanvas() {
    return this.scene.icanvas;
  }

  get scene() {
    return this.scene_cache;
  }
  set scene(value) {
    this.scene_cache = value;
    if (this.area) {
      this.area.scene = value;
    }
  }

  constructor(json) {
    this.json = json;

    if (this.json.layer === undefined) {
      this.json.layer = InteractiveLayers.DefaultLayerJson.name;
    }

    this.parseJson();
  }
  clone() {
    return new Interactive(this.json);
  }

  static getNameFromFilename(file) {
    return file.name;
  }
  static newJsonFromFile(file) {
    return {
      name: this.getNameFromFilename(file),
      "interactive.area": {
        "geometry.rectangle": [0.0, 0.0, 0.05, 0.05],
      },
    };
  }

  getScreenSpaceAreaRect() {
    return this.area.getScreenSpaceAreaRect();
  }
  setAreaReletivePosition(point) {
    this.area.setAreaReletivePosition(point);
  }

  addActionFromJson(j) {
    var a = newActionFromJson(j);
    this.actions.push(a);
  }

  parseJson() {
    let json = this.json;
    for (let each in json) {
      if (each == Action.typename) {
        this.addActionFromJson(json[each]);
      } else if (each == InteractiveInput.typename) {
        var i = new InteractiveInput(this, json[each]);
        this.inputs.push(i);
      } else if (each == InteractiveArea.typename) {
        this.area = new InteractiveArea(this, json[each]);
      } else if (each == Actions.typename) {
        for (let each_action in json[each]) {
          this.addActionFromJson(json[each][each_action]);
        }
      }else if (each=="ui"){
        this.ui = new UserInterfaceElements(()=>{return this.getScreenSpaceAreaRect();}, json[each]);
      }
    }
  }

  getResourcePath() {
    return this.json.resourcePath || this.scene.getResourcePath();
  }

  // update(icanvas) {
  //   this.area?.update(icanvas);
  // }

  start() {
    if(this.area){
      this.area.on_visual_loaded=()=>{ 
        this.ui?.addToDOM();
      };
    }
    this.area?.start();
    
  }

  start_loaded() {
   
  }
  stop() {
    this.area?.stop();
    this.ui?.removeFromDOM();
  }

  drawFrame(icanvas) {
    this.area?.drawFrame(icanvas);
    this.ui?.updateDOM();
  }

  isIEventOnInteractiveArea(ievent) {
    return this.area?.isIEventOnInteractiveArea(ievent);
  }

  mousedown(ievent) {
    this.area?.mousedown(ievent);
  }

  mouseup(ievent) {
    this.area?.mouseup(ievent);
  }

  mousemove(ievent) {
    this.area?.mousemove(ievent);
  }

  onMouseUp(ievent, area) {
    ievent.isStopPropagation = true;

    this.runActions();
  }

  onInteractiveInput(ievent, iinput) {
    ievent.isStopPropagation = true;
    this.runActions();
  }

  runActions() {
    for (let each in this.actions) {
      this.actions[each].run(this);
    }
  }

  apply(command) {
    if (command instanceof Object) {
      // if ("image_set" in command) {
      //   let value = command.image_set;
      //   this.scene?.set_image(value);
      // }
    }
  }

  keypress(ievent) {
    for (let each in this.inputs) {
      this.inputs[each].keypress(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }

  activate(ievent) {
    if (ievent.activate_value == "interactive.input") {
      for (let each in this.inputs) {
        this.inputs[each].activate(ievent);
        if (ievent.isStopPropagation) {
          break;
        }
      }
    }
  }

  onTouchTap(ievent) {
    // if (this.area) {
    //let event = e.events[0];
    this.area?.onTouchTap(ievent);
    // }
  }
  onTouchPan(ievent) {}
  onTouchSwipe(ievent) {
    for (let each in this.inputs) {
      this.inputs[each].onTouchSwipe(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  onTouchDistance(ievent) {}
  onTouchRotate(ievent) {}
  onTouchGesture(ievent) {}

  collectCanvasElements(result) {
    this.area?.collectCanvasElements(result);
  }
  file_dropped(ievent) {}
  drag_file(ievent) {}
}
