import { ReadonlyProperty } from "./view/propertyEditor";
import { StackLayout } from "./view/stackLayout";
import { AuthorInterface } from "./sceneAuthorInterface/authorInterface";

export class WebApplication {
  resources;
  name;
  json;
  versionNumber;

  constructor(resources, name = "", versionNumber = undefined) {
    this.resources = resources;
    this.name = name;
    this.versionNumber = versionNumber;
  }

  stroageItemName() {
    return this.resources.combineJsonResourceName(this.name, "application.storage");
  }

  initialize() {
    this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());

    this.addSettings();

    if (this.getSetting("isLoadLocalStorage") == false || this.getSetting("isLoadApplicationLocalStorage") == false) {
      this.json = {};
      this.addSettings();
    }
    this.saveState();
  }

  addSettings() {
    if (!this.json.hasOwnProperty("settings")) {
      this.json.settings = {};
    }
    if (!this.json.hasOwnProperty("default")) {
      this.json.default = {};
      this.json.default.settings = {};
    }
    if (!this.json.hasOwnProperty("ui")) {
      this.json.ui = {};
      this.json.ui.settings = {};
    }
    if (!this.json.default.settings.hasOwnProperty("isLoadLocalStorage")) {
      this.json.default.settings.isLoadLocalStorage = true;
    }
    if (!this.json.ui.settings.hasOwnProperty("isLoadLocalStorage")) {
      this.json.ui.settings.isLoadLocalStorage = {};
    }

    if (!this.json.default.settings.hasOwnProperty("isLoadApplicationLocalStorage")) {
      this.json.default.settings.isLoadApplicationLocalStorage = true;
    }
    if (!this.json.ui.settings.hasOwnProperty("isLoadApplicationLocalStorage")) {
      this.json.ui.settings.isLoadApplicationLocalStorage = {};
    }

    if (!this.json.default.settings.hasOwnProperty("isLoadAccountLocalStorage")) {
      this.json.default.settings.isLoadAccountLocalStorage = false;
    }
    if (!this.json.ui.settings.hasOwnProperty("isLoadAccountLocalStorage")) {
      this.json.ui.settings.isLoadAccountLocalStorage = {};
    }

    if (!this.json.default.settings.hasOwnProperty("isSaveAccountLocalStorage")) {
      this.json.default.settings.isSaveAccountLocalStorage = true;
    }
    if (!this.json.ui.settings.hasOwnProperty("isSaveAccountLocalStorage")) {
      this.json.ui.settings.isSaveAccountLocalStorage = {};
    }

    if (!this.json.default.settings.hasOwnProperty("isPreCacheAssets")) {
      this.json.default.settings.isPreCacheAssets = false;
    }
    if (!this.json.ui.settings.hasOwnProperty("isPreCacheAssets")) {
      this.json.ui.settings.isPreCacheAssets = {};
    }

    if (!this.json.default.settings.hasOwnProperty("isUseREST")) {
      this.json.default.settings.isUseREST = false;
    }
    if (!this.json.ui.settings.hasOwnProperty("isUseREST")) {
      this.json.ui.settings.isUseREST = {};
    }

    //this.json.ui.settings.isPreCacheAssets.isEnabled = false;
  }

  getSetting(name) {
    if (this.json.settings.hasOwnProperty(name)) {
      return this.json.settings[name];
    }
    if (this.json.default.settings.hasOwnProperty(name)) {
      return this.json.default.settings[name];
    }
    return undefined;
  }
  get settings() {
    return this.json.settings;
  }

  saveState() {
    let json_copy = Object.assign({}, this.json, {});
    json_copy.ui = undefined;
    json_copy.default = undefined;
    this.resources.setLocalStorageItemAsJson(this.stroageItemName(), json_copy,false);
  }
  shutdown() {
    this.saveState();
  }

  collectEditableProperties(layout) {
    let version_property = Object.assign(new ReadonlyProperty(), {
      name: "version",
      getValue: () => {
        return this.versionNumber;
      },
    });
    layout.addAsTableRow(version_property.getEditorElements());

    layout.addAsTableRow(
      Object.assign(new ReadonlyProperty(), {
        name: "url",
        getValue: () => {
          return window.location.href;
        },
      }).getEditorElements()
    );
  }

  getAuthorInterfaceName() {
    return this.name;
  }

  createAuthorInterfaceElement() {
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    AuthorInterface.collectEditablePropertiesFromProperties(this.json.settings, this.json.ui.settings,this.json.default.settings, layout);
    return layout.element;
  }

  addAuthorInterfaceElementToTreeview(treeview) {
    let elm = this.createAuthorInterfaceElement();
    treeview.addItem(this.getAuthorInterfaceName(), elm, true);
  }

  initializeExternalModules(state){

  }
  initializeInternalModules(state){
    
  }
  initializeSceneModules(state){
    
  }
}
