//https://github.com/ren-yuan/c2.js
let c2 = require("c2.js");

export function mouseEventToPoint(e) {
  return new c2.Point(e.x, e.y);
}

export class Geometry {
  shape;
  isRelativeTo;
  draw(icanvas, drawScope) { }
  to_point(isRelativeTo) { }

  get_absolute_rect_shape() { }
}

//https://c2js.org/docs/classes/geometry.rect.html#constructor
export class RectangleGeometry extends Geometry {
  rect_pos_size;

  constructor(geometry, isRelativeTo = undefined) {
    super();
    this.isRelativeTo = isRelativeTo;
    this.rect_pos_size = geometry;
    super.shape = new c2.Rect(
      this.rect_pos_size[0],
      this.rect_pos_size[1],
      this.rect_pos_size[2],
      this.rect_pos_size[3]
    );
  }
  to_point() {
    let absolute_shape = this.get_absolute_shape();

    return absolute_shape.p;
  }
  draw(icanvas, drawScope) {
    let absolute_shape = this.get_absolute_shape();
    icanvas.draw_point(absolute_shape.p, 3, drawScope);
    icanvas.draw_rect(absolute_shape, drawScope);
  }

  get_absolute_shape() {
    if (this.isRelativeTo) {
      let relativeTo = this.isRelativeTo.toRect();
      let result = new c2.Rect(
        relativeTo.w * this.shape.p.x,
        relativeTo.h * this.shape.p.y,
        relativeTo.w * this.shape.w,
        relativeTo.h * this.shape.h
      );

      return result;
    }
    return this.shape;
  }
  get_absolute_rect_shape() {
    return this.get_absolute_shape();
  }

  containsPoint(point) {

    let absolute_shape = this.get_absolute_shape();
    return absolute_shape.intersects(point);
  }
}

//https://c2js.org/docs/classes/geometry.rect.html#constructor
export class PointWithMediaGeometry extends Geometry {
  pos;
  get_media;

  constructor(geometry, get_media, isRelativeTo = undefined) {
    super();
    this.isRelativeTo = isRelativeTo;
    this.pos = geometry;
    this.get_media = get_media;

    super.shape = new c2.Point(
      this.pos[0],
      this.pos[1]
    );
  }

  to_point() {
    let absolute_shape = this.get_absolute_shape();

    return absolute_shape.p;
  }

  draw(icanvas, drawScope) {
    let absolute_shape = this.get_absolute_shape();
    icanvas.draw_point(absolute_shape.p, 3, drawScope);
    icanvas.draw_rect(absolute_shape, drawScope);
  }

  get_absolute_shape() {


    var media = this.get_media();

    if (media && !media.isLoading()) {
      var size_px = media.pixel_size();

      if (this.isRelativeTo) {
        let relativeTo = this.isRelativeTo.toRect(false);

        size_px[0] = size_px[0] / relativeTo.w;
        size_px[1] = size_px[1] / relativeTo.h;

        // size_px[0] =  relativeTo.w/size_px[0] ;
        // size_px[1] =  relativeTo.h/size_px[1] ;
      }

      var rect_shape = new c2.Rect(this.shape.x, this.shape.y, size_px[0], size_px[1]);

    } else {
      var rect_shape = new c2.Rect(this.shape.x, this.shape.y, 0, 0);
    }

    if (this.isRelativeTo) {
      let relativeTo = this.isRelativeTo.toRect();
      let result = new c2.Rect(
        relativeTo.w * rect_shape.p.x,
        relativeTo.h * rect_shape.p.y,
        relativeTo.w * rect_shape.w,
        relativeTo.h * rect_shape.h
      );

      return result;
    }

    return rect_shape;
  }

  get_absolute_rect_shape() {
    return this.get_absolute_shape();
  }

  containsPoint(point) {

    let absolute_shape = this.get_absolute_shape();
    return absolute_shape.intersects(point);
  }
}
