import { Actions } from '../../sceneGraph/actions/actions.js';
import { UserInterfaceElement } from './UserInterfaceElement.js';


export class ButtonUserInterfaceElement extends UserInterfaceElement {
    content;

    actions = new Actions();
    constructor(elements, json) {
        super(elements, json);
        this.content = json.content;
        this.actions.parseJson(json);
    }

    addToDOM() {

        let id = crypto.randomUUID();

        this.element = Object.assign(document.createElement("button"), {
            id: id
        });
        this.element.innerHTML = this.content;

        if (this.json.margin) {
            this.element.style.margin = this.json.margin;
        }

        this.element.addEventListener("click", (event) => {
            this.onClick();
        });

        this.elements.div.appendChild(this.element);
    }

    onClick() {
        this.actions.runActions(this.elements);
    }
}
