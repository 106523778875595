
export class NoInternalAuth {

    constructor(state) {
    }

    initialize() { console.log("No Internal Auth") }

    async authenticate() {

    }

    saveState() { }

    shutdown() { this.saveState(); }
}