import { startClient } from "../../cabin-library/client/src/main.js";
import { WebApplication } from "../../cabin-library/client/src/webApplication.js";
import { WebApplicationState } from "../../cabin-library/client/src/WebApplicationState.js";

import { LakesideCabinServer } from "../../cabin-library/client/src/LakesideCabinServer.js";
let versionNumber = "0.1.3";

class CardCraftWebApplication extends WebApplication {
  constructor(state) {
    super(state.resources, "Card Craft", state.clientVersionNumber);
  }
}

class CardCraftServer extends LakesideCabinServer {
  constructor(state) {
    super(state.resources, state.application);
  }
}

// function hasWindowParent() {
//   return window != window.parent;
// }


// function sendMessageToWindowParent(message) {
//   if (hasWindowParent()) {
//     window.parent.postMessage(message, '*');
//     console.log('send message' + message);
//   }
// }

// const postMessageHandler = (event) => {
//   let body = event.data;

//   console.log(body);

//   if (body) {
//     if (body.action == 'userinfo') {
//     }

//     if (body.action == 'start') {
//   sendMessageToWindowParent({ action: 'ready' });  
//     }
//   }
// };

// window.addEventListener('message', postMessageHandler, false);



const GAME_URL = "process.env.CHESS_URL";
const GAME_ID = "process.env.GAME_ID";
const PLAYER_ID = "process.env.PLAYER_ID";

const parent = window.location?.ancestorOrigins[0];
document.cookie = "cookieID=1; SameSite=None; Secure";

// GAME STATE LISTENER
let playerData;


window.addEventListener("load", function () {
	if (window.location.ancestorOrigins.length == 0) {
		playerData = { GAME_URL, GAME_ID, PLAYER_ID };
		window.postMessage({
			action: "start",          // Action key.
			receiptToken: "{receipt-token}", // Receipt validation token.
		});
	}
}, false);


const gameCenterActionsIn = { // An interface to match expected actions.
	userinfo: (data) => { // "userinfo" action handler.
		if (data?.chessUrl || false && data?.gameId || false && data?.userId || false) {
			playerData = { GAME_URL: data.chessUrl, GAME_ID: data.gameId, PLAYER_ID: data.userId };
		} else {
			playerData = null;
		}
	},
	start: (data, returnMessage) => { // "start" action handler.
		if (playerData?.PLAYER_ID) { // Start a new full game.
			//renderCanvas(playerData);
		}
	},
	ready: (data, returnMessage) => {
		// if (document?.referrer != window.location.href && !!document.referrer) {
		if (window.location.ancestorOrigins.length != 0 && parent) {
			const { action, receiptToken } = data; // Return message handler for the current event.
			window.parent.postMessage({
				action: action,          // Action key.
				receiptToken: "{receipt-token}", // Receipt validation token.
			}, parent);
			console.log(`\n[CARD CRAFT] Message sent to ${parent}:  [action: ${action}]`);

			if (data.origin != this.origin) {
				const res = { action: "return", receiptToken: `${action}-${receiptToken}` };
				console.log(`\n[CARD CRAFT] Message sent to ${data.origin}:  [action: ${res.action}]`);
				returnMessage(res); // Invoke an expected action.
			}

		}
	},
	exit: (data, returnMessage) => {
		if (window.location.ancestorOrigins.length != 0 && parent) {
			const { action, receiptToken } = data; // Return message handler for the current event.
			window.parent.postMessage({
				action: action,          // Action key.
				receiptToken: "{receipt-token}", // Receipt validation token.
			}, parent);
			console.log(`\n[CARD CRAFT] Message sent to ${parent}:  [action: ${action}]`);
			if (data.origin != this.origin) {
				const res = { action: "return", receiptToken: `${action}-${receiptToken}` };
				console.log(`\n[CARD CRAFT] Message sent to ${data.origin}:  [action: ${res.action}]`);
				returnMessage(res); // Invoke an expected action.
			}
		}
	}
};


window.addEventListener("message", function (event) {
	if (event.origin != this.origin) {
		console.log(`[CARD CRAFT] Message received from ${event.origin}:  [action: ${event.data.action}]`);
	}
	if (!(event.data?.action in gameCenterActionsIn)) { return; } // Unknown action.
	const returnMessage = (message) => { window.postMessage(message); };// Send the message receipt.
	gameCenterActionsIn[event.data.action](event.data, returnMessage);
}, false);


(async () => {
  let state = new WebApplicationState();
  state.clientVersionNumber=versionNumber;

  let newWebApplication = (withState) => {
    return new CardCraftWebApplication(withState);
  };

  let newServer = (withState) => {
    return new CardCraftServer(withState);
  };

  await startClient(state, newWebApplication, newServer,false,false);
  
})();

if (window.location.ancestorOrigins.length != 0 && parent) {
 // const { action, receiptToken } = data; // Return message handler for the current event.
  window.parent.postMessage({
    action: "ready",          // Action key.
    receiptToken: "{receipt-token}", // Receipt validation token.
  }, parent);
  console.log(`\n[CARD CRAFT] Message sent to ${parent}:  [action: ${ "ready"}]`);
}