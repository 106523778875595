import { CanvasElement } from "../display/CanvasElement.js";
import { VideoCanvasElement } from "../display/videoCanvasElement";
import { FileResource } from "./FileResource";

let c2 = require("c2.js");

export class VideoResource extends FileResource {
  resource_element;
  resource_request;
  isError;
  loading_promise;
  resource_client_area;
  canvas_element;

  constructor(resource_request) {
    super();
    this.resource_request = resource_request;

    //let url = this.resource_request.toUrlPath();

    // https://gist.github.com/aferriss/99dc65bbc079e28f95030ff34e5a161b

    this.resource_element = document.createElement("video");
    this.resource_element.autoplay = true;
    this.resource_element.muted = true;
    this.resource_element.loop = true;
    this.resource_element.playsinline = true;
   
    //this.resource_element.src = url;
   this.resource_element.controls = false;
    this.resource_element.name = this.resource_request.name;


    let self = this;

    this.loading_promise = new Promise((resolve, reject) => {
      self.resource_element.addEventListener("canplay", function () {
        self.loading_promise = undefined;
        self.resource_request.onVisualLoaded?.();
        resolve(self);
      });

      self.resource_element.addEventListener("error", function () {
        self.loading_promise = undefined;
        self.isError=true;
        resolve(self);
      });
     // this.resource_element.load();
    });


    resource_request.server_file_cache.loadFromCacheOrUrl(this.resource_element, this.resource_request.toUrlPath());

  }

  isLoading() {
    return this.loading_promise != undefined;
  }

  getLoadingPromise() {
    return this.loading_promise;
  }
  // draw(icanvas) {
  //   icanvas.playing_video_resource(this);
  // }
  toRect(icanvas=undefined) {
    // if (!image || image.width == 0 || image.height == 0) {
    //   console.warn(`scene ${this.name} undefined image`);
    //   return new c2.Rect(0, 0, 0, 0);
    // }
    if(icanvas){
      var hRatio = icanvas.get_width() / this.resource_element.videoWidth;
      var vRatio = icanvas.get_height() / this.resource_element.videoHeight;
      var ratio = Math.min(hRatio, vRatio);
      var result = new c2.Rect(0, 0, this.resource_element.videoWidth * ratio, this.resource_element.videoHeight * ratio);
      return result;
    }

    var result = new c2.Rect(0, 0, this.resource_element.videoWidth , this.resource_element.videoHeight );
    return result;
  }

  getCanvasElement() {
    if (this.canvas_element == undefined) {
      this.canvas_element = new VideoCanvasElement(this, this.resource_element);
    }
    return this.canvas_element;
  }

  pixel_size(){
    return [this.resource_element.videoWidth,this.resource_element.videoHeight];
  }
}
